import React, { FC, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import { store } from '@/store';
import { GlobalStyles } from '@/styles/global';
import { RootNavigation } from './navigation/root.navigation';
import { createHttpInstances } from './services/http-client';
import { i18n } from './services/i18n';
import { createStorageManagement } from './services/storage-management/storage-management';

createHttpInstances(store);
const storageManagement = createStorageManagement(store);

export const Core: FC = () => {
  useEffect(() => {
    storageManagement.init();
    return () => storageManagement.clear();
  }, []);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <GlobalStyles />
        <RootNavigation />
      </I18nextProvider>
    </Provider>
  );
};
