export const fontSize = {
  xsmall: 10,
  small: 13,
  medium: 15,
  large: 50,
  xlarge: 70,
  xxlarge: 450,
  h1: 30,
  h2: 20,
  h3: 18,
  h4: 16,
  h5: 15,
  h6: 14,
  p: 12,
  iconSmall: 10,
  icon: 22,
  iconMedium: 36,
  iconLarge: 70,
  index: 8,
};

export const fontFamily = {
  primary: 'osgill',
};

export const offset = {
  xsmall: 5,
  small: 10,
  medium: 15,
  large: 20,
  xlarge: 30,
  xxlarge: 50,
  xxxlarge: 60,
};

export const borderRadius = {
  small: 3,
  medium: 5,
  large: 10,
  xlarge: 25,
};

export const leftPanel = {
  width: 260,
  offset: 60,
};

export const inputHeight = 40;
export const dialogShadow = '0 10px 20px 0 rgba(0, 0, 0, 0.3), 0 0 20px 0 rgba(0, 0, 0, 0.22)';
export const selectMenuShadow = '0 0 2px 1px rgba(0, 0, 0, 0.3)';
export const minWindowWidth = 360;
export const maxContentWidth = 480;
