import { Enum } from '@/common/utils';
import { RoutePaths } from '../../navigation.models';
import { rootRoutePaths } from '../root';

export type unauthenticatedRoutes = Enum<typeof unauthenticatedRoutes>;
export const unauthenticatedRoutes = Enum('LOGIN', 'LOGOUT', 'VERIFY', 'VOUCHER_REDEEM_LANDING');

export const unauthenticatedRoutePaths: RoutePaths<unauthenticatedRoutes> = {
  [unauthenticatedRoutes.LOGIN]: `${rootRoutePaths.UNAUTHENTICATED}`,
  [unauthenticatedRoutes.LOGOUT]: `${rootRoutePaths.UNAUTHENTICATED}logged-out`,
  [unauthenticatedRoutes.VERIFY]: `${rootRoutePaths.UNAUTHENTICATED}verify`,
  [unauthenticatedRoutes.VOUCHER_REDEEM_LANDING]: `${rootRoutePaths.UNAUTHENTICATED}redeem-voucher`,
};

export type logoutReason = Enum<typeof logoutReason>;
export const logoutReason = Enum('TOKEN', 'USER_PROFILE', 'COMMON_ERROR');

export type unauthenticatedRoutesSearch = {
  [unauthenticatedRoutes.LOGOUT]: { reason: logoutReason };
};
