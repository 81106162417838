import React, { ButtonHTMLAttributes, FC } from 'react';

import cn from 'classnames';

import { styles } from './styles';

export type ButtonTheme = 'primary' | 'secondary' | 'error';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  theme?: ButtonTheme;
};

export const Button: FC<Props> = ({ children, theme = 'primary', className, ...props }) => {
  return (
    <button css={styles} className={cn([className, theme])} {...props}>
      {children}
    </button>
  );
};
