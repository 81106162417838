import React, { FC, useMemo } from 'react';

import { css, Global } from '@emotion/core';
import emotionNormalize from 'emotion-normalize';

import { fontFamily, fontSize, minWindowWidth } from './constants';
import { fonts } from './fonts';
import { theme } from './theme';
import { iconfont } from './_generated_font_definition';

export const GlobalStyles: FC = () => {
  const styles = useMemo(
    () =>
      css({
        html: {
          fontSize: fontSize.medium,
          backgroundColor: theme.backgroundPrimary,
        },
        body: {
          minWidth: minWindowWidth,
        },
        'html, span, p, div, i, button': {
          fontFamily: fontFamily.primary,
          fontWeight: 400,
          color: theme.textPrimary,
        },
        'div, input': {
          boxSizing: 'border-box',
        },
        input: {
          minWidth: 0,
        },
        a: {
          textDecoration: 'none',
          outline: 'none',
        },
        button: {
          backgroundColor: theme.backgroundPrimary,
          outline: 'none',
          border: 'none',
        },
      }),
    []
  );

  return <Global styles={[emotionNormalize, fonts, styles, iconfont]} />;
};
