import { constants, rem, Styles, theme } from '@/styles';

export const styles: Styles = {
  cursor: 'pointer',
  padding: 0,
  fontSize: rem(constants.fontSize.h4),
  transition: 'color .2s',

  '&.underlined': {
    textDecoration: 'underline',
  },

  '&.primary': {
    color: theme.primary,

    '&:hover': {
      color: theme.primaryHover,
    },

    '&:focus': {
      color: theme.primaryFocus,
    },

    '&:active': {
      color: theme.primaryActive,
    },
  },

  '&.grey': {
    color: theme.textSecondary,

    '&:hover': {
      color: theme.textSecondaryHover,
    },

    '&:focus': {
      color: theme.textSecondaryFocus,
    },

    '&:active': {
      color: theme.textSecondaryActive,
    },
  },

  '&.error': {
    color: theme.error,

    '&:hover': {
      color: theme.errorHover,
    },

    '&:focus': {
      color: theme.errorFocus,
    },

    '&:active': {
      color: theme.errorActive,
    },
  },

  '&.disabled': {
    color: theme.textDisabled,
    cursor: 'not-allowed',

    '&:hover': {
      color: theme.textDisabled,
    },
  },
};
