import { Store } from 'redux';
import { tap } from 'rxjs/operators';

import { history } from '@/store';
import { rootRoutePaths, rootRoutes } from '../../navigation';
import { userProfile } from './profile';

type LoginParams = {
  store: Store;
  preventRedirect?: boolean;
};

export const login = ({ store, preventRedirect = false }: LoginParams) =>
  userProfile({ store, preventRedirect }).pipe(
    tap((data) => {
      if (data !== undefined && preventRedirect !== true) {
        history.push(rootRoutePaths[rootRoutes.AUTHENTICATED]);
      }
    })
  );
