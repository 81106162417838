import { Axios } from 'axios-observable';
import { Store } from 'redux';

import { rootState } from '@/store';
import { authenticationInterceptor, loadingIndicatorInterceptor } from './interceptors';

let httpInstances: ReturnType<typeof createHttpInstances>;

export const createHttpInstances = (store: Store<rootState>) => {
  const unauthorized = Axios.create({});
  const authorized = Axios.create({});
  loadingIndicatorInterceptor(unauthorized, store);
  loadingIndicatorInterceptor(authorized, store);
  authenticationInterceptor(authorized, store);
  const instances = {
    unauthorized,
    authorized,
  };
  httpInstances = instances;
  return instances;
};

const getHttpInstances = () => httpInstances;

export const httpClient = getHttpInstances;
