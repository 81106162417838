import { constants, rem, Styles, theme } from '@/styles';

export const styles: Styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 143,
  backgroundImage: 'url(/images/phototron.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',

  '&.default': {
    '.status-box': {
      backgroundColor: theme.brandFourth,
    },
  },

  '&.success': {
    '.status-box': {
      backgroundColor: theme.success,
    },
  },

  '&.error': {
    '.status-box': {
      backgroundColor: theme.brandPrimary,
    },
  },

  '.status-box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 63,
    height: 63,
    backgroundColor: theme.success,
    borderRadius: constants.borderRadius.large,

    '&__icon': {
      color: theme.textWhite,
      fontSize: rem(constants.fontSize.iconMedium),
    },
  },
};
