import { useLayoutEffect, useRef, useState } from 'react';

import isArray from 'lodash/isArray';

import { StatusWrapperStatus } from '../components/status-wrapper';

export const getLocationOrigin = () => `${typeof location.origin === 'undefined' ? `${location.protocol}//${location.host}` : location.origin}/verify`;

type DataList = { list?: any[] };

export const getDataStatus = ({ data, isLoading, error }: { data: any | any[]; isLoading: boolean; error: any }): StatusWrapperStatus => {
  if (isLoading) {
    return 'loading';
  }

  if (error !== undefined) {
    return 'failure';
  }

  if (
    data === undefined ||
    (isArray(data) && data.length === 0) ||
    (data !== undefined && isArray((data as DataList).list) && (data as DataList).list?.length === 0)
  ) {
    return 'no-results';
  }

  return 'success';
};

export const mergeDataStatues = (statuses: StatusWrapperStatus[], preventStatuses: StatusWrapperStatus[] = []): StatusWrapperStatus => {
  const orderOfImportance: StatusWrapperStatus[] = ['loading', 'failure', 'no-results', 'success'];
  const filteredOrderOfImportance = orderOfImportance.filter((entity) => !preventStatuses.includes(entity));

  for (const entity of filteredOrderOfImportance) {
    if (statuses.includes(entity)) {
      return entity;
    }
  }

  return 'success';
};

export const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1);
};

export const useAfterLayoutUpdate = () => {
  const afterPaintRef = useRef<(() => void) | undefined>(undefined);
  useLayoutEffect(() => {
    if (afterPaintRef.current !== undefined) {
      afterPaintRef.current();
      afterPaintRef.current = undefined;
    }
  });

  return (fn: () => void) => (afterPaintRef.current = fn);
};

export const redirect = (url: string) => window.location.replace(url);
