import { constants, rem, Styles, theme } from '@/styles';

export const styles: Styles = {
  display: 'flex',
  flexDirection: 'column',

  '.title': {
    marginTop: constants.offset.xlarge,
    marginBottom: constants.offset.xlarge,
    whiteSpace: 'pre-line',
    fontSize: rem(constants.fontSize.h1),
    fontWeight: 400,
    color: theme.textPrimary,
  },

  '.description': {
    marginBottom: constants.offset.xlarge,
    whiteSpace: 'pre-line',
    lineHeight: 1.2,
    color: theme.textPrimary,
  },

  '.button': {
    marginTop: constants.offset.xlarge,
    marginBottom: constants.offset.xlarge,
  },
};
