import moment from 'moment';

import { Enum } from '@/common/utils';

export type UserRole = Enum<typeof UserRole>;
export const UserRole = Enum('USER');

export type TokenStatus = Enum<typeof TokenStatus>;
export const TokenStatus = Enum('INVALID', 'VALID', 'REFRESHING', 'EMPTY');

export type UserDataResponse = {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  userId: string;
  objectId: string;
  roles: UserRole[];
};

export interface UserDataVM extends UserDataResponse {}
export class UserDataVM {
  constructor({ roles, ...props }: UserDataResponse) {
    Object.assign(this, { ...props, roles: roles || [] });
  }
}

export type AuthenticationResponse = {
  access_token: string;
  id_token: string;
  token_type: string;
  not_before: number;
  expires_in: number;
  expires_on: number;
  resource: string;
  id_token_expires_in: number;
  profile_info: string;
  refresh_token: string;
  refresh_token_expires_in: number;
};

export class AuthenticationVM {
  accessToken: string;
  refreshToken: string;
  expiredOn: number;
  tokenType: string;
  expiredIn: number;
  constructor({ access_token, refresh_token, refresh_token_expires_in, token_type }: AuthenticationResponse) {
    this.accessToken = access_token;
    this.refreshToken = refresh_token;
    this.expiredOn = getExpiredOnValue(refresh_token_expires_in);
    this.expiredIn = refresh_token_expires_in;
    this.tokenType = token_type;
  }
}

const getExpiredOnValue = (value: number) => moment().add(value, 's').valueOf();
