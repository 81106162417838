import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Link } from '@/common/components/link';
import { logout } from '@/core/services/authentication';
import { makeGetUserStatus } from '@/store/authentication';
import { styles } from './styles';

const getUserStatus = makeGetUserStatus();

export const TopBar: FC = () => {
  const [t] = useTranslation();
  const userStatus = useSelector(getUserStatus);
  const handleLogout = useCallback(() => logout(), []);
  return <div css={styles}>{userStatus?.isLogged === true ? <Link onClick={handleLogout}>{t('button.logOut')}</Link> : null}</div>;
};
