import { constants, Styles } from '@/styles';

export const styles: Styles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  minHeight: constants.offset.xxxlarge,
  paddingLeft: constants.offset.xlarge,
  paddingRight: constants.offset.xlarge,
  paddingTop: constants.offset.medium,
  paddingBottom: constants.offset.medium,
};
