import { Observable, Observer, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

import { rootState, store as rootStore } from '@/store';

export type GenericRetryConfig = {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: number[];
};

export const genericAxiosRetry = <T>({ maxRetryAttempts = 3, scalingDuration = 1000, excludedStatusCodes = [] }: GenericRetryConfig = {}) =>
  retryWhen<T>((attempts) =>
    attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => e === error?.response?.status)) {
          return throwError(error);
        }

        return timer(retryAttempt * scalingDuration);
      })
    )
  );

export const rxStore: (store?: typeof rootStore) => Observable<rootState> = (store = rootStore) =>
  Observable.create((observer: Observer<rootState>) => {
    observer.next(store.getState());
    const sub = store.subscribe(() => {
      observer.next(store.getState());
    });

    return sub;
  });
