import { createSelector } from 'reselect';

import { interfaceReducer } from './reducer';

type MakeGetNumberOfActiveActions = interfaceReducer['interface']['numberOfActiveActions'];

const getInterface = (state: interfaceReducer) => state.interface;

export const makeGetNumberOfActiveActions = () =>
  createSelector<interfaceReducer, interfaceReducer['interface'], MakeGetNumberOfActiveActions>(
    getInterface,
    ({ numberOfActiveActions }) => numberOfActiveActions || 0
  );

type MakeGetLoadingSectionActive = interfaceReducer['interface']['loadingSectionActive'];

export const makeGetLoadingSectionActive = () =>
  createSelector<interfaceReducer, interfaceReducer['interface'], MakeGetLoadingSectionActive>(
    getInterface,
    ({ loadingSectionActive }) => loadingSectionActive
  );
