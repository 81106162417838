type UnionFromTuple<T> = T extends (infer U)[] ? U : never;

export const Enum = <T extends string[]>(...args: T) =>
  Object.freeze(
    args.reduce(
      (acc, next) => ({
        ...acc,
        [next]: next,
      }),
      Object.create(null)
    ) as { [P in UnionFromTuple<typeof args>]: P }
  );

export type Enum<T extends object> = T[keyof T];

export interface CommonError {
  status: string;
  message: string;
  body?: { [key: string]: any };
}

export class CommonError {
  constructor(data: CommonError) {
    Object.assign(this, data);
  }
}

export type ResponseError = {
  code: string;
  errorId: string;
  message: string;
  timestamp: string;
};

export const checkIsResponseError = (data: any): data is ResponseError =>
  typeof data?.code === 'string' && typeof data?.errorId === 'string' && typeof data?.message === 'string' && typeof data?.timestamp === 'string';
