import { initReactI18next } from 'react-i18next';

import i18nBase from 'i18next';
import backend from 'i18next-xhr-backend';

import { APPLICATION_LNG_STORAGE_KEY, DEFAULT_LNG } from './constants';
import { overrifdeChangeLanguage } from './helpers';

const getApplicationLng = () => {
  const lng = window.localStorage.getItem(APPLICATION_LNG_STORAGE_KEY);
  if (lng) {
    return lng;
  }

  window.localStorage.setItem(APPLICATION_LNG_STORAGE_KEY, DEFAULT_LNG);
  return DEFAULT_LNG;
};

const createI18nInstance = () => {
  const lng = getApplicationLng();
  const i18nInstance = i18nBase.createInstance();
  i18nInstance
    .use(backend)
    .use(initReactI18next)
    .init({
      lng,
      fallbackLng: DEFAULT_LNG,
      debug: false,
      ns: 'common',
      defaultNS: 'common',
      interpolation: {
        escapeValue: false,
        formatSeparator: '.',
      },
      react: {
        wait: true,
      },
    });

  return overrifdeChangeLanguage(i18nInstance);
};

export const i18n = createI18nInstance();
