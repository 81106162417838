import { Action, ActionType, createAction } from 'typesafe-actions';

import { TokenStatus, UserDataVM } from '@/core/models/authentication';

export const authenticationActions = {
  getUserData: createAction('[AUTHENTICATION] get user data')(),
  setUserData: createAction('[AUTHENTICATION] set user data')<UserDataVM | undefined>(),
  clearUserData: createAction('[AUTHENTICATION] clear user data')(),
  setTokenStatus: createAction('[AUTHENTICATION] set token status')<TokenStatus>(),
};

export type authenticationActions = Action | ActionType<typeof authenticationActions>;
