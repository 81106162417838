import { Store } from 'redux';

import { history, resetStoreAction, store as globalStore } from '@/store';
import { unauthenticatedRoutePaths, unauthenticatedRoutes } from '../../navigation';
import { clearCookies } from './cookies-managment';
import { triggerLocalStorageLogoutEvent } from './storage';

type LogoutParams = {
  preventEmission?: boolean;
  preventRedirect?: boolean;
  store?: Store;
};

export const logout = ({ preventEmission, preventRedirect, store }: LogoutParams = {}) => {
  clearCookies();
  (store || globalStore).dispatch(resetStoreAction());
  if (preventEmission !== true) {
    triggerLocalStorageLogoutEvent();
  }

  if (preventRedirect !== true) {
    history.push(unauthenticatedRoutePaths[unauthenticatedRoutes.LOGOUT]);
  }
};
