import { css } from '@emotion/core';

export const iconfont = css`
  @font-face {
    font-family: "iconfont";
    src: url('/fonts/Iconfont/iconfont.eot');
    src: url('/fonts/Iconfont/iconfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Iconfont/iconfont.woff') format('woff'),
      url('/fonts/Iconfont/iconfont.ttf') format('truetype'),
      url('/fonts/Iconfont/iconfont.svg') format('svg');
  }

  .icon {
    font-style: normal;
    font-weight: 400;

    &:before {
      font-family: "iconfont";
      display: block;
      font-size: inherit;
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
    }
    
    &.mdi-alert-circle:before {
      content: "\\ea01";
    }
    &.mdi-alert:before {
      content: "\\ea02";
    }
    &.mdi-checkbox-marked-circle-outline:before {
      content: "\\ea03";
    }
    &.mdi-help:before {
      content: "\\ea04";
    }
    &.os-logo:before {
      content: "\\ea05";
    }
  }
`;