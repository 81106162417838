import { createReducer } from 'typesafe-actions';

import { TokenStatus, UserDataVM } from '@/core/models/authentication';
import { FeatureStateType } from '../helpers';
import { authenticationActions } from './actions';

type authenticationState = {
  isLoading: boolean;
  tokenStatus: TokenStatus;
  userData: UserDataVM | undefined;
};

const INITIAL_STATE: authenticationState = {
  isLoading: false,
  tokenStatus: TokenStatus.EMPTY,
  userData: undefined,
};

const reducer = createReducer<authenticationState, authenticationActions>(INITIAL_STATE)
  .handleAction(authenticationActions.getUserData, (state) => ({ ...state, isLoading: true }))
  .handleAction(authenticationActions.setUserData, (state, action) => ({ ...state, userData: action.payload, isLoading: false }))
  .handleAction(authenticationActions.clearUserData, () => ({ ...INITIAL_STATE }))
  .handleAction(authenticationActions.setTokenStatus, (state, action) => ({ ...state, tokenStatus: action.payload }));

export const authenticationReducer = { authentication: reducer };
export type authenticationReducer = FeatureStateType<typeof authenticationReducer>;
