import { Styles, theme } from '@/styles';

export const styles: Styles = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '.wrapper': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.circle': {
    display: 'block',
    width: 15,
    margin: '0 4px',
    height: 15,
    borderRadius: '50%',
    overflow: 'hidden',
    animation: 'grow 1s ease-in-out infinite alternate',

    '&--first': {
      backgroundColor: theme.brandSecondary,
    },

    '&--second': {
      backgroundColor: theme.brandThird,
      animationDelay: '0.2s',
    },

    '&--third': {
      backgroundColor: theme.brandPrimary,
      animationDelay: '0.4s',
    },
  },

  '.small .circle': {
    width: 9,
    margin: '0 3px',
    height: 9,
  },

  '@keyframes grow': {
    '0%': {
      transform: 'translateX(-50%) scale(0)',
    },

    '60%': {
      transform: 'translateX(0) scale(1)',
    },
  },
};
