import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

import { authenticationActions, authenticationEpics, authenticationReducer } from './authentication';
import { interfaceActions, interfaceReducer } from './interface';
import { resetMiddleware } from './middleware';

const INITIAL_STATE = {};

export type rootActions = authenticationActions | interfaceActions;
export type rootState = authenticationReducer & interfaceReducer;

export const history = createBrowserHistory();

const makeStore = () => {
  const epicMiddleware = createEpicMiddleware<rootActions, rootActions, any>();
  const reduxMiddleware = composeWithDevTools(compose(applyMiddleware(epicMiddleware), resetMiddleware()));
  const rootEpic = combineEpics(...authenticationEpics);
  const rootReducer = combineReducers({
    ...authenticationReducer,
    ...interfaceReducer,
    router: connectRouter(history),
  });

  const store = createStore(rootReducer, INITIAL_STATE, reduxMiddleware);
  epicMiddleware.run(rootEpic);
  return store;
};

export const store = makeStore();
