import { Callback, i18n } from 'i18next';

import { APPLICATION_LNG_STORAGE_KEY } from './constants';

const createOverridedChangeLanguage = (baseChangeLanguage: i18n['changeLanguage']) =>
  function overridedChangeLanguage(this: i18n, lng: string, callback?: Callback, preventEmission?: boolean): ReturnType<i18n['changeLanguage']> {
    return baseChangeLanguage(lng, (error, t) => {
      if (preventEmission !== true) {
        window.localStorage.setItem(APPLICATION_LNG_STORAGE_KEY, lng);
      }

      callback && callback(error, t);
    });
  };

export type ChangeLanguage = ReturnType<typeof createOverridedChangeLanguage>;

export const overrifdeChangeLanguage = (instance: i18n) => {
  const baseChangeLanguage = instance.changeLanguage.bind(instance);
  instance.changeLanguage = createOverridedChangeLanguage(baseChangeLanguage);
  return instance as i18n & { changeLanguage: ChangeLanguage };
};
