import { Epic } from 'redux-observable';
import { filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getUserProfile } from '@/core/services/authentication';
import { authenticationActions } from './actions';
import { authenticationReducer } from './reducer';

export const getUserDataEpic: Epic<authenticationActions, authenticationActions, authenticationReducer> = (action$) =>
  action$.pipe(
    filter(isActionOf(authenticationActions.getUserData)),
    switchMap(() => getUserProfile().pipe(map((data) => authenticationActions.setUserData(data?.userData))))
  );

export const authenticationEpics = [getUserDataEpic];
