import { Store } from 'redux';
import { of } from 'rxjs';
import { catchError, filter, ignoreElements, map, switchMap, take } from 'rxjs/operators';

import { genericAxiosRetry, getEnv, rxStore } from '@/common/utils';
import { authenticationActions, getAuthentication } from '@/store/authentication';
import { UserDataResponse, UserDataVM } from '../../models/authentication';
import { httpClient } from '../http-client';
import { getCookies } from './cookies-managment';
import { logout } from './logout';

const config = getEnv();

const USER_PROFILE_ENDPOINT = `${config.REACT_APP_API_URL}/oauth/me/profile`;
const PROFILE_EXCLUDED_STATUS_CODES = [401, 403];

export const getUserProfile = () =>
  httpClient()
    .authorized.get<UserDataResponse>(USER_PROFILE_ENDPOINT)
    .pipe(
      genericAxiosRetry({ excludedStatusCodes: PROFILE_EXCLUDED_STATUS_CODES }),
      catchError(() => of({ status: 403, data: undefined })),
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return {
            status,
            userData: new UserDataVM(data),
          };
        }

        return undefined;
      })
    );

type UserProfileParams = {
  store: Store;
  preventRedirect?: boolean;
};

export const userProfile = ({ store, preventRedirect = false }: UserProfileParams) => {
  const cookies = getCookies();
  if (cookies === undefined) {
    return of<UserDataVM | undefined>(undefined);
  }

  return rxStore(store).pipe(
    map(getAuthentication),
    filter(({ isLoading }) => !isLoading),
    take(1),
    switchMap(({ userData }) => {
      if (userData === undefined) {
        store.dispatch(authenticationActions.getUserData());
        return rxStore(store).pipe(
          map(getAuthentication),
          filter(({ isLoading }) => !isLoading),
          map(({ userData }) => userData),
          take(1)
        );
      }

      return of(userData);
    }),
    switchMap((userData) => {
      if (userData === undefined) {
        logout({ store, preventRedirect });
        if (preventRedirect === true) {
          return of<UserDataVM | undefined>(userData);
        }

        return of<UserDataVM | undefined>(userData).pipe(ignoreElements());
      }

      return of(userData);
    })
  );
};
