import { createSelector } from 'reselect';

import { UserRole } from '@/core/models/authentication';
import { authenticationReducer } from './reducer';

type MakeGetUserData = authenticationReducer['authentication']['userData'];

export const getAuthentication = (state: authenticationReducer) => state.authentication;
export const getTokenStatus = (state: authenticationReducer) => getAuthentication(state).tokenStatus;

export const makeGetUserData = () =>
  createSelector<authenticationReducer, authenticationReducer['authentication'], MakeGetUserData>(getAuthentication, ({ userData }) =>
    userData !== undefined ? userData : undefined
  );

type MakeGetUserStatus = {
  isLogged: boolean;
  isDataLoading: boolean;
  roles: UserRole[];
};

export const makeGetUserStatus = () =>
  createSelector<authenticationReducer, authenticationReducer['authentication'], MakeGetUserStatus>(getAuthentication, ({ userData, isLoading }) => ({
    isLogged: userData !== undefined,
    isDataLoading: isLoading,
    roles: userData !== undefined ? userData.roles : [],
  }));
