import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Redirect, Route } from 'react-router';

import { throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { interfaceActions } from '@/store/interface';
import { GuardProps, GuardResponse, PrivateRouteProps } from '../../navigation/navigation.models';

type PrivateRouteState = {
  isLoading: boolean;
  guardResponse: GuardResponse | null;
};

export function PrivateRoute({ component: RouteComponent, guard, ...rest }: PrivateRouteProps) {
  const store = useStore();
  const [state, setState] = useState<PrivateRouteState>({
    isLoading: true,
    guardResponse: null,
  });

  useEffect(() => {
    const subscription = guard({ ...rest, store })
      .pipe(
        take(1),
        catchError((error) => {
          console.warn('Please cope with errors in the guard method.');
          return throwError(error);
        })
      )
      .subscribe((guardResponse) => setState({ guardResponse, isLoading: false }));

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    store.dispatch(interfaceActions.toggleLoadingSection(state.isLoading));
  }, [state.isLoading]);

  const redirectUrl = state?.guardResponse?.redirectUrl;
  const guardProps = state?.guardResponse?.guardProps;

  if (!RouteComponent) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        state.isLoading ? null : !redirectUrl ? (
          <RouteComponent {...props} {...({ guardProps } as GuardProps)} />
        ) : (
          <Redirect
            to={{
              pathname: redirectUrl,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
