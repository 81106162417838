import { AuthenticationVM } from '../../models/authentication';
import { COOKIE_ACCESS_TOKEN, COOKIE_EXPIRES_IN, COOKIE_EXPIRES_ON, COOKIE_REFRESH_TOKEN, COOKIE_TOKEN_TYPE } from './constants';

export const getCookies = (): AuthenticationVM | undefined => {
  const accessToken = localStorage.getItem(COOKIE_ACCESS_TOKEN) || undefined;
  const refreshToken = localStorage.getItem(COOKIE_REFRESH_TOKEN) || undefined;
  const tokenType = localStorage.getItem(COOKIE_TOKEN_TYPE) || undefined;
  const expiredOn = localStorage.getItem(COOKIE_EXPIRES_ON) || undefined;
  const expiredIn = localStorage.getItem(COOKIE_EXPIRES_IN) || undefined;
  if (accessToken === undefined || refreshToken === undefined || tokenType === undefined || expiredOn === undefined || expiredIn === undefined) {
    return undefined;
  }

  return {
    accessToken,
    refreshToken,
    tokenType,
    expiredOn: parseInt(expiredOn, 10),
    expiredIn: parseInt(expiredIn, 10),
  };
};

export const setCookies = ({ accessToken, refreshToken, expiredOn, tokenType, expiredIn }: AuthenticationVM) => {
  localStorage.setItem(COOKIE_ACCESS_TOKEN, accessToken);
  localStorage.setItem(COOKIE_REFRESH_TOKEN, refreshToken);
  localStorage.setItem(COOKIE_TOKEN_TYPE, tokenType);
  localStorage.setItem(COOKIE_EXPIRES_ON, String(expiredOn));
  localStorage.setItem(COOKIE_EXPIRES_IN, String(expiredIn));
};

export const clearCookies = () => {
  localStorage.removeItem(COOKIE_ACCESS_TOKEN);
  localStorage.removeItem(COOKIE_REFRESH_TOKEN);
  localStorage.removeItem(COOKIE_TOKEN_TYPE);
  localStorage.removeItem(COOKIE_EXPIRES_ON);
  localStorage.removeItem(COOKIE_EXPIRES_IN);
};
