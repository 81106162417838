import { AxiosRequestConfig } from 'axios';
import { Axios } from 'axios-observable';
import { Store } from 'redux';
import { filter, map, take } from 'rxjs/operators';

import { rxStore } from '@/common/utils';
import { authenticationActions, getTokenStatus } from '@/store/authentication';
import { AuthenticationVM } from '../../../models/authentication';
import { getCookies, refreshTokens, REQ_AUTHORIZATION_HEADER } from '../../authentication';

const AUTHENTICATION_PROMISE_REJECT = { response: { status: 403 } };

function configClientWithAccessToken(config: AxiosRequestConfig, cookies: AuthenticationVM) {
  config.headers = {
    ...config.headers,
    [REQ_AUTHORIZATION_HEADER]: `${cookies.tokenType} ${cookies.accessToken}`,
  };

  return config;
}

export const authenticationInterceptor = (instance: Axios, storeInstance: Store) => {
  instance.interceptors.request.use(async (config) => {
    const isTokenValid = await rxStore(storeInstance)
      .pipe(
        map(getTokenStatus),
        filter((tokenStatus) => tokenStatus !== 'REFRESHING'),
        take(1)
      )
      .toPromise();

    if (isTokenValid !== 'INVALID') {
      const cookies = getCookies();
      if (cookies !== undefined) {
        if (isTokenValid === 'EMPTY') {
          storeInstance.dispatch(authenticationActions.setTokenStatus('VALID'));
        }

        return configClientWithAccessToken(config, cookies);
      }
    }

    return Promise.reject(AUTHENTICATION_PROMISE_REJECT);
  });

  instance.interceptors.response.use(undefined, async (error) => {
    if (error?.response?.status === 401) {
      storeInstance.dispatch(authenticationActions.setTokenStatus('REFRESHING'));
      const newAuthData = await refreshTokens().toPromise();
      if (newAuthData !== undefined) {
        storeInstance.dispatch(authenticationActions.setTokenStatus('VALID'));
        return instance.request(configClientWithAccessToken(error?.response.config, newAuthData)).toPromise();
      }

      storeInstance.dispatch(authenticationActions.setTokenStatus('INVALID'));
    } else if (error?.response?.status === 403) {
      console.warn('We need to logout!');
    }

    return Promise.reject(error);
  });

  return;
};
