import { Axios } from 'axios-observable';
import { Store } from 'redux';

import { interfaceActions } from '@/store/interface';

export const loadingIndicatorInterceptor = (instance: Axios, storeInstance: Store) => {
  instance.interceptors.request.use((data) => {
    storeInstance.dispatch(interfaceActions.increaseNumberOfActiveActions());
    return data;
  });
  instance.interceptors.response.use(
    (data) => {
      storeInstance.dispatch(interfaceActions.reduceNumberOfActiveActions());
      return data;
    },
    (data) => {
      storeInstance.dispatch(interfaceActions.reduceNumberOfActiveActions());
      throw data;
    }
  );
};
