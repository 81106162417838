interface Theme {
  brandPrimary: string;
  brandSecondary: string;
  brandThird: string;
  brandFourth: string;
  success: string;
  warning: string;
  info: string;
  error: string;
  errorFocus: string;
  errorHover: string;
  errorActive: string;
  primary: string;
  primaryActive: string;
  primaryFocus: string;
  primaryHover: string;
  primaryDisabled: string;
  backgroundPrimary: string;
  backgroundPrimaryActive: string;
  backgroundPrimaryHover: string;
  backgroundPrimaryFocus: string;
  backgroundPrimaryDisabled: string;
  backgroundSecondary: string;
  backgroundSecondaryDisabled: string;
  backgroundThird: string;
  backgroundFourth: string;
  backgroundFourthActive: string;
  backgroundFourthHover: string;
  backgroundAccent: string;
  borderPrimary: string;
  borderPrimaryActive: string;
  borderPrimaryFocus: string;
  borderPrimaryDisabled: string;
  borderAccent: string;
  borderSecondary: string;
  textWhite: string;
  textPrimary: string;
  textPrimaryActive: string;
  textPrimaryHover: string;
  textPrimaryFocus: string;
  textPrimaryDisabled: string;
  textSecondary: string;
  textSecondaryActive: string;
  textSecondaryHover: string;
  textSecondaryFocus: string;
  textSecondaryDisabled: string;
  textThird: string;
  textAccent: string;
  textDisabled: string;
  shadowPrimary: string;
}

class Theme {
  constructor(theme: Theme) {
    Object.assign(this, theme);
  }
}

const palette = {
  primary01: '#453c90',
  primary02: '#b2abf0',
  primary03: '#746cac',
  primary04: '#302965',
  secondary: '#ff5f00',
  third: '#e6007d',
  fourth: '#d40058',
  white: '#ffffff',
  grey01: '#fafafa',
  grey02: '#f6f5f9',
  grey03: '#f5f5f5',
  grey04: '#dddddd',
  grey05: '#999999',
  grey06: '#333333',
  warmGrey01: '#eeebeb',
  warmGrey02: '#e8e3e3',
  warmGrey03: '#dbd9d9',
  warmGrey04: '#d4cdcd',
  warmGrey05: '#9c9c9c',
  success: '#39a339',
  information: '#2979af',
  warning: '#ffb000',
  error01: '#d40000',
  error02: '#ee99bc',
  error03: '#d56073',
  error04: '#b3014b',
};

export const theme = new Theme({
  brandPrimary: palette.primary01,
  brandSecondary: palette.secondary,
  brandThird: palette.third,
  brandFourth: palette.fourth,
  success: palette.success,
  warning: palette.warning,
  info: palette.information,
  error: palette.error01,
  errorActive: palette.error04,
  errorFocus: palette.error02,
  errorHover: palette.error03,
  primary: palette.primary01,
  primaryActive: palette.primary04,
  primaryFocus: palette.primary03,
  primaryHover: palette.primary02,
  primaryDisabled: palette.grey05,
  backgroundPrimary: palette.white,
  backgroundPrimaryActive: palette.warmGrey02,
  backgroundPrimaryHover: palette.grey03,
  backgroundPrimaryFocus: palette.grey02,
  backgroundPrimaryDisabled: palette.grey03,
  backgroundSecondary: palette.grey02,
  backgroundSecondaryDisabled: palette.grey05,
  backgroundThird: palette.grey01,
  backgroundFourth: palette.warmGrey01,
  backgroundFourthActive: palette.warmGrey03,
  backgroundFourthHover: palette.warmGrey02,
  backgroundAccent: palette.grey03,
  borderPrimary: palette.grey04,
  borderPrimaryActive: palette.primary01,
  borderPrimaryFocus: palette.primary02,
  borderPrimaryDisabled: palette.grey05,
  borderSecondary: palette.grey06,
  borderAccent: palette.grey04,
  textWhite: palette.white,
  textPrimary: palette.grey06,
  textPrimaryActive: palette.primary04,
  textPrimaryFocus: palette.primary03,
  textPrimaryHover: palette.primary02,
  textPrimaryDisabled: palette.warmGrey02,
  textSecondary: palette.grey05,
  textSecondaryActive: palette.error04,
  textSecondaryHover: palette.error03,
  textSecondaryFocus: palette.error02,
  textSecondaryDisabled: palette.warmGrey02,
  textThird: palette.grey05,
  textDisabled: palette.grey05,
  textAccent: palette.primary01,
  shadowPrimary: palette.grey05,
});
