import React, { FC } from 'react';

import { Icon } from '@/common/components/icon';
import { styles } from './styles';

type Status = 'default' | 'success' | 'error';

type Props = {
  status?: Status;
};

const getIconStatus = (status: Status) => {
  switch (status) {
    case 'success':
      return 'mdi-checkbox-marked-circle-outline';

    case 'error':
      return 'mdi-alert';

    default:
      return 'os-logo';
  }
};

export const Phototron: FC<Props> = ({ status = 'default' }) => {
  const iconName = getIconStatus(status);
  return (
    <div css={styles} className={status}>
      <div className='status-box'>
        <Icon className='status-box__icon' name={iconName} />
      </div>
    </div>
  );
};
