import { Enum, getEnv, getLocationOrigin } from '@/common/utils';

type Params = {
  page: AccountHubPages;
};

type AccountHubPages = Enum<typeof AccountHubPages>;
const AccountHubPages = Enum('B2C_1A_signup_signin', 'B2C_1A_PasswordReset');

const config = getEnv();

const getAccountHubUrls = ({ page }: Params) =>
  [
    config.REACT_APP_AUTHENTICATION_API_URL,
    `/oauth2/v2.0/authorize?p=${page}&`,
    `client_id=${config.REACT_APP_AUTHENTICATION_CLIENT_ID}&`,
    'nonce=defaultNonce&',
    `redirect_uri=${getLocationOrigin()}&`,
    `scope=openid%20${config.REACT_APP_AUTHENTICATION_API_SCOPE_URL}%2Fplatform%2Fread%20${config.REACT_APP_AUTHENTICATION_API_SCOPE_URL}%2Fplatform%2Fuser_impersonation%20${config.REACT_APP_AUTHENTICATION_API_SCOPE_URL}%2Fplatform%2Fwrite%20${config.REACT_APP_AUTHENTICATION_API_SCOPE_URL}%2Fplatform%2Foffline_access%20offline_access&`,
    'response_type=code&',
    'prompt=login',
  ].join('');

export const getLoginPageUrl = () => getAccountHubUrls({ page: AccountHubPages.B2C_1A_signup_signin });
export const getResetPasswordPageUrl = () => getAccountHubUrls({ page: AccountHubPages.B2C_1A_PasswordReset });
