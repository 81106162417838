import { Emission } from '../storage-management/models';
import { APPLICATION_LOGIN_STORAGE_KEY, APPLICATION_LOGIN_STORAGE_VALUE, APPLICATION_LOGOUT_STORAGE_KEY, APPLICATION_LOGOUT_STORAGE_VALUE } from './constants';
import { login } from './login';
import { logout } from './logout';

export const authenticationStorageEvents = ({ event: { key, newValue }, store }: Emission) => {
  if (window.opener) {
    return;
  }

  if (key === APPLICATION_LOGOUT_STORAGE_KEY && newValue === APPLICATION_LOGOUT_STORAGE_VALUE) {
    logout({ store, preventEmission: true });
  }

  if (key === APPLICATION_LOGIN_STORAGE_KEY && newValue === APPLICATION_LOGIN_STORAGE_VALUE) {
    login({ store }).toPromise();
  }
};

export const triggerLocalStorageLogoutEvent = () => {
  window.localStorage.setItem(APPLICATION_LOGOUT_STORAGE_KEY, APPLICATION_LOGOUT_STORAGE_VALUE);
  window.localStorage.removeItem(APPLICATION_LOGOUT_STORAGE_KEY);
};

export const triggerLocalStorageLoginEvent = () => {
  window.localStorage.setItem(APPLICATION_LOGIN_STORAGE_KEY, APPLICATION_LOGIN_STORAGE_VALUE);
  window.localStorage.removeItem(APPLICATION_LOGIN_STORAGE_KEY);
};
