import React, { AnchorHTMLAttributes, FC } from 'react';

import cn from 'classnames';

import { styles } from './styles';

export type linkTheme = 'primary' | 'error' | 'grey';

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  theme?: linkTheme;
  underlined?: boolean;
  disabled?: boolean;
};

export const Link: FC<Props> = ({ children, className, theme = 'primary', underlined, disabled, ...props }) => {
  return (
    <a css={styles} {...props} className={cn([className, theme, { underlined }, { disabled }])}>
      {children}
    </a>
  );
};
