import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/common/components/button';
import { Phototron } from '@/common/components/phototron';
import { styles } from './styles';

type ErrorProps = {
  title: string;
  description: string;
};

export const Error: FC<ErrorProps> = ({ title, description }) => {
  const [t] = useTranslation();
  const reload = useCallback(() => window.location.reload(), []);

  return (
    <div css={styles}>
      <Phototron status='error' />

      <div className='title'>{title}</div>
      <div className='description'>{description}</div>

      <Button className='button' theme='error' onClick={reload}>
        {t('button.reloadPage')}
      </Button>
    </div>
  );
};
