import { constants, Styles, theme } from '@/styles';

export const styles: Styles = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',

  '.main': {
    width: '100%',
    margin: '0 auto',
    maxWidth: constants.maxContentWidth,
    padding: `0 ${constants.offset.xlarge}px ${constants.offset.xxxlarge}px`,

    '&__container': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 500,
    },
  },

  '.brand': {
    '&__container': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 13,
      height: 210,
    },

    '&__color': {
      '&--first': {
        height: `${400 / 7}%`,
        backgroundColor: theme.brandSecondary,
      },

      '&--second': {
        height: `${200 / 7}%`,
        backgroundColor: theme.brandThird,
      },

      '&--third': {
        height: `${100 / 7}%`,
        backgroundColor: theme.brandPrimary,
      },
    },
  },
};

export const loadingSectionStyles: Styles = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.backgroundPrimary,
  zIndex: 1,
};
