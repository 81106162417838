import { string } from 'yup';

const VALIDATION_MESSAGE = {
  VOUCHER: 'validationMessage.voucher',
};

export const VOUCHER_VALIDATOR = string()
  .trim()
  .required(VALIDATION_MESSAGE.VOUCHER)
  .min(14, VALIDATION_MESSAGE.VOUCHER)
  .max(14, () => VALIDATION_MESSAGE.VOUCHER);
