import { css } from '@emotion/core';

const osgill = `
  @font-face {
    font-family: 'osgill';
    src: url('/fonts/OSGill/OSGill-Bold.eot');
    src: url('/fonts/OSGill/OSGill-Bold.eot?#iefix') format('embedded-opentype'), url('/fonts/OSGill/OSGill-Bold.woff2') format('woff2'),
      url('/fonts/OSGill/OSGill-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'osgill';
    src: url('/fonts/OSGill/OSGill-BoldItalic.eot');
    src: url('/fonts/OSGill/OSGill-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/OSGill/OSGill-BoldItalic.woff2') format('woff2'), url('/fonts/OSGill/OSGill-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'osgill';
    src: url('/fonts/OSGill/OSGill-Semibold.eot');
    src: url('/fonts/OSGill/OSGill-Semibold.eot?#iefix') format('embedded-opentype'), url('/fonts/OSGill/OSGill-Semibold.woff2') format('woff2'),
      url('/fonts/OSGill/OSGill-Semibold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'osgill';
    src: url('/fonts/OSGill/OSGill-Regular.eot');
    src: url('/fonts/OSGill/OSGill-Regular.eot?#iefix') format('embedded-opentype'), url('/fonts/OSGill/OSGill-Regular.woff2') format('woff2'),
      url('/fonts/OSGill/OSGill-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'osgill';
    src: url('/fonts/OSGill/OSGill-Italic.eot');
    src: url('/fonts/OSGill/OSGill-Italic.eot?#iefix') format('embedded-opentype'), url('/fonts/OSGill/OSGill-Italic.woff2') format('woff2'),
      url('/fonts/OSGill/OSGill-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'osgill';
    src: url('/fonts/OSGill/OSGill-Light.eot');
    src: url('/fonts/OSGill/OSGill-Light.eot?#iefix') format('embedded-opentype'), url('/fonts/OSGill/OSGill-Light.woff2') format('woff2'),
      url('/fonts/OSGill/OSGill-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
`;

export const fonts = css`
  ${osgill}
`;
