import { Enum } from '@/common/utils';
import { RoutePaths } from '../navigation.models';

export type rootRoutes = Enum<typeof rootRoutes>;
export const rootRoutes = Enum('AUTHENTICATED', 'UNAUTHENTICATED');

export const rootRoutePaths: RoutePaths<rootRoutes> = {
  [rootRoutes.UNAUTHENTICATED]: '/',
  [rootRoutes.AUTHENTICATED]: '/auth',
};
