import React, { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Error } from '../error';

type Props = WithTranslation;
type State = { hasError: false };

class PageWrapperClass extends PureComponent<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return <Error title={t('error.unexpectedError')} description={t('error.common')}></Error>;
    }

    return this.props.children;
  }
}

export const PageWrapper = withTranslation()(PageWrapperClass);
