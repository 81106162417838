import { rgba, Styles, theme } from '@/styles';

export const styles: Styles = {
  '@keyframes example': {
    '0%': { backgroundPosition: '0% 0%' },
    '50%': { backgroundPosition: '50% 50%' },
    '100%': { backgroundPosition: '100% 100%' },
  },

  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: 4,
  background: `
    linear-gradient(
      to right,
      ${rgba(theme.primary, 0.3)} 0%,
      ${rgba(theme.primary, 0.3)} 40%,
      ${theme.primary} 41%,
      ${theme.primary} 59%,
      ${rgba(theme.primary, 0.3)} 60%,
      ${rgba(theme.primary, 0.3)} 100%
    )`,
  backgroundSize: '250% 100%',
  boxShadow: `0 0 5px ${theme.shadowPrimary}`,
  animation: 'example 3s linear infinite',
};
