import React, { FC, HTMLAttributes } from 'react';

import { Interpolation } from '@emotion/core';
import cn from 'classnames';

import { styles } from './styles';

type Size = 'small' | 'medium';

type Props = HTMLAttributes<HTMLDivElement> & {
  size?: Size;
  css?: Interpolation<undefined>;
};

export const LoadingSection: FC<Props> = ({ size = 'medium', css, ...props }) => (
  <div css={[styles, css]} {...props}>
    <div className={cn(['wrapper', size])}>
      <div className='circle circle--first' />
      <div className='circle circle--second' />
      <div className='circle circle--third' />
    </div>
  </div>
);
