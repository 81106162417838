import { constants, rem, Styles, theme } from '@/styles';

export const styles: Styles = {
  width: '100%',
  marginTop: constants.offset.xlarge,
  marginBottom: constants.offset.large,
  borderTop: `solid 1px ${theme.borderPrimary}`,
  paddingTop: constants.offset.small,

  '.footer': {
    '&__items': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },

    '&__item': {
      flex: '0 0 auto',
      padding: `${constants.offset.small}px ${constants.offset.medium}px`,

      '.item': {
        margin: 0,
        fontSize: rem(constants.fontSize.small),
        fontWeight: 400,
      },
    },
  },
};
