import { constants, Styles, theme } from '@/styles';

export const styles: Styles = {
  padding: `${constants.offset.medium}px ${constants.offset.large}px`,
  borderRadius: constants.borderRadius.xlarge,
  cursor: 'pointer',
  transition: 'box-shadow .15s ease-in, background-color .15s ease-in',
  fontWeight: 600,
  minWidth: 120,

  ':disabled': {
    cursor: 'not-allowed',
  },

  '&.primary': {
    color: theme.textWhite,
    border: `1px solid ${theme.primary}`,
    backgroundColor: theme.primary,

    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
    },

    '&:hover:not(:disabled)': {
      backgroundColor: theme.primaryHover,
      border: `1px solid ${theme.primaryHover}`,
    },

    '&:disabled': {
      backgroundColor: theme.backgroundSecondaryDisabled,
      border: `1px solid ${theme.backgroundSecondaryDisabled}`,
    },

    '&:active': {
      backgroundColor: theme.primaryActive,
      border: `1px solid ${theme.primaryActive}`,
    },
  },

  '&.secondary': {
    color: theme.primary,
    backgroundColor: theme.backgroundPrimary,
    border: `1px solid ${theme.primary}`,

    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
    },

    '&:hover:not(:disabled)': {
      backgroundColor: theme.backgroundPrimaryHover,
    },

    '&:disabled': {
      border: `1px solid ${theme.backgroundSecondaryDisabled}`,
      color: theme.textDisabled,
    },

    '&:active': {
      backgroundColor: theme.backgroundPrimaryActive,
    },
  },

  '&.error': {
    color: theme.textWhite,
    backgroundColor: theme.brandFourth,
    border: `1px solid ${theme.brandFourth}`,

    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.errorFocus}`,
    },

    '&:hover:not(:disabled)': {
      backgroundColor: theme.errorHover,
      border: `1px solid ${theme.errorHover}`,
    },

    '&:disabled': {
      backgroundColor: theme.backgroundSecondaryDisabled,
      border: `1px solid ${theme.backgroundSecondaryDisabled}`,
    },

    '&:active': {
      backgroundColor: theme.errorActive,
      border: `1px solid ${theme.errorActive}`,
    },
  },
};
