import hexRgb from 'hex-rgb';

import { fontSize } from './constants';

export const rem = (val: number) => `${val / (fontSize.medium || 1)}rem`;

export const rgba = (hex: string, alpha: number) => {
  const rgb = hexRgb(hex, { format: 'array' });
  rgb.pop();
  return `rgba(${rgb}, ${alpha})`;
};
