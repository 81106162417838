import { createReducer } from 'typesafe-actions';

import { FeatureStateType } from '../helpers';
import { interfaceActions } from './actions';

type interfaceState = {
  numberOfActiveActions: number;
  loadingSectionActive: boolean;
};

const INITIAL_STATE: interfaceState = {
  numberOfActiveActions: 0,
  loadingSectionActive: false,
};

const reducer = createReducer<interfaceState, interfaceActions>(INITIAL_STATE)
  .handleAction(interfaceActions.increaseNumberOfActiveActions, (state) => ({ ...state, numberOfActiveActions: state.numberOfActiveActions + 1 }))
  .handleAction(interfaceActions.reduceNumberOfActiveActions, (state) => ({ ...state, numberOfActiveActions: state.numberOfActiveActions - 1 }))
  .handleAction(interfaceActions.toggleLoadingSection, (state, action) => ({ ...state, loadingSectionActive: action.payload }));

export const interfaceReducer = { interface: reducer };
export type interfaceReducer = FeatureStateType<typeof interfaceReducer>;
