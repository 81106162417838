export const REQ_AUTHORIZATION_HEADER = `Authorization`;
export const API_ENDPOINT = '/B2C_1A_signup_signin/oauth2/v2.0/token';

export const COOKIE_ACCESS_TOKEN = 'authenticationAccessTokenKey';
export const COOKIE_REFRESH_TOKEN = 'authenticationRefreshTokenKey';
export const COOKIE_TOKEN_TYPE = 'authenticationTokenTypeKey';
export const COOKIE_EXPIRES_ON = 'authenticationExpiresOn';
export const COOKIE_EXPIRES_IN = 'authenticationExpiresIn';

export const APPLICATION_LOGOUT_STORAGE_KEY = 'applicationLogout';
export const APPLICATION_LOGOUT_STORAGE_VALUE = 'I72f23f23f23457345bvA3J}i"8fa123fv23v202vf323"Cjl+KS?DUu;A-wUr]';
export const APPLICATION_LOGIN_STORAGE_KEY = 'applicationLogin';
export const APPLICATION_LOGIN_STORAGE_VALUE = '4Z-Cv!`67jj}A67435b745b7435b3b457656jCfZjPD>9<{G25h23X#)>q23fpC8';
