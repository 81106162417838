import React, { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

export const Header: FC<HTMLAttributes<HTMLElement>> = () => {
  const [t] = useTranslation();
  return (
    <div css={styles}>
      <img alt={t('header.companyLogo')} className='image' src='/images/os-logo.svg' />
    </div>
  );
};
